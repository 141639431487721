* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-size: 15.5px;
  padding: 0;
}

body {
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  line-height: 1.3;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}
