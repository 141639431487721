/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #db3433;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 0.125rem;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 6.25rem;
  height: 100%;
  box-shadow:
    0 0 0.625rem #db3433,
    0 0 0.3125rem #db3433;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0rem, -0.25rem);
  -ms-transform: rotate(3deg) translate(0rem, -0.25rem);
  transform: rotate(3deg) translate(0rem, -0.25rem);
}
